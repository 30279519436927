import IDEE$plugin$MouseSRS from './facade/js/mousesrs';
import IDEE$control$MouseSRSControl from './facade/js/mousesrscontrol';
import IDEE$impl$control$Mouse from './impl/ol/js/extendedMouse';
import IDEE$impl$control$MouseSRSControl from './impl/ol/js/mousesrscontrol';

if (!window.IDEE.plugin) window.IDEE.plugin = {};
if (!window.IDEE.control) window.IDEE.control = {};
if (!window.IDEE.impl) window.IDEE.impl = {};
if (!window.IDEE.impl.control) window.IDEE.impl.control = {};
window.IDEE.plugin.MouseSRS = IDEE$plugin$MouseSRS;
window.IDEE.control.MouseSRSControl = IDEE$control$MouseSRSControl;
window.IDEE.impl.control.Mouse = IDEE$impl$control$Mouse;
window.IDEE.impl.control.MouseSRSControl = IDEE$impl$control$MouseSRSControl;
